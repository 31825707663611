﻿.pv-accordion-area {   
 .pv-accordion-header {
        margin-top: 15px;
        cursor: pointer;
        background-color: white;
        border-top: solid 1px $color-pv-bg-dark-purple;
        border-bottom: solid 1px $color-pv-bg-dark-purple;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        font-weight: bold; 
        padding: 10px 15px;
        & span {
            display: none;
        }

        &:after {
            position: relative;
            top: 1px;
            display: inline-block;
            float: right;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\e114';
            padding-right: 5px;
            padding-top: 3px;
        }

        &.accordion-header-active:after {
            content: '\e113';
        }

        &:hover {
            background-color: $color-pv-bg-light-purple;
            color: #555;
        }

        &.accordion-header-active, .pv-accordion-body .pv-accordion-header {
            border-bottom: none;
            background-color: $color-pv-bg-light-brown;
            color: #555;
        }
    }

     .accordion-header-active:hover {
        background-color: $color-pv-bg-light-purple;
        color: #555;
    }

   

 .pv-accordion-body {
        background-color: $color-pv-bg-light-brown;
        font-family: $font-family-sans-serif; //Todo: Byt till rätt typsnitt
        padding-left: 14px;
        margin-top: -11px;
        line-height: $line-height-text;

        /*Sätter klasser på undernivån*/
        .pv-accordion-header {
            background-color: $color-pv-bg-light-brown;
            border: none;
            margin-left: 0px;
            padding-left: 0px;

            &:after {
                position: relative;
                top: 4px;
                display: inline-block;
                float: none;
                font-family: 'Glyphicons Halflings';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: '\e252';
                padding-left: 12px;
            }

            &.accordion-header-active {
                margin-left: 0px;
                padding-left: 0px;

                &:after {
                    content: '\e253';
                }
            }
        }

        &.pv-accordion-body {
            border: none;
        }
 }
 }

