﻿.pv-block-startsidatjanstepuff {

    &--puff {
        padding: 0;

        &-inner {
            background: $color-pv-bg-light-brown;
            text-align: center;
            border-radius: 6px;
            padding: 20px 50px;

            & p {
                font-size: $font-size-large;
                margin-top: 40px;
            }

            & img {
                max-width: 100%;
            }

            & a {
                text-decoration: underline;
            }
        }
    }
    /* Tjänstepuff 1*/
    &--first p, &--first a{
        color: $color-pv-txt-darker-green;
    }
    /* Tjänstepuff 2*/
    &--second p, &--second a{
        color: $color-pv-txt-darker-blue;
    }
    /* Tjänstepuff 3*/
    &--third p, &--third a{
        color: $color-pv-bg-dark-brown;
    }
}
