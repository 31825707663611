﻿.pv-sida-sokresultat {
    @media screen and (min-width: 768px) {
        &--container {
            margin: 0 auto;
            float: none;
        }

         &--form {
             .btn-search{
                  float:right;
             }
         }
    }

    &--header {
        margin-top: 30px;
        margin-bottom: 36px;
        text-align:center;
    }

    &--info{
        display:inline-block;
        margin-top:15px;
    }
    &--form, &--info {
        margin-bottom: 30px;

        .form-control{
            width:97%;
        }
        .col-sm-11{
            padding-left:0;
            padding-right:0;
        }
        .col-sm-1{
            padding-right:0;
            padding-left:0;
        }
    }

    &--bestBet {
        background: $color-pv-bg-light-purple;
        padding: 20px 20px 0;
    }
}
