﻿/*Här lägger vi egna sassvariabler*/

$icon-size: 20px;
$icon-width: 37px;
$icon-height: 37px;
$fa-font-path:   "/Assets/fonts/";

$screen-lg-min: 1200px;
$screen-md-min: 992px;
$screen-sm-min: 768px;

$screen-sm-max: 991px;
$screen-xs-max: 767px;

$line-height-text: 1.500em;

$footer-margin: 50px;