﻿.pv-text {
    &--standard {
        h1 {
            margin-bottom: 36px;
        }

        h2 {
            margin-bottom: 26px;
        }

        & .well h2 {
            margin-bottom: 22px;
        }
    }

    @media (max-width: $screen-sm-max) {
        &--standard {
            h1 {
                margin-bottom: 32px;
                margin-top: 7px;
            }
        }
    }
}
