﻿input[type='radio']:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    top: 0px;
    position: relative;
    background-color: white;
    border: 1px solid $gray-light !important;
    content: '';
    display: block;
    visibility: visible;
    }
   

input[type='radio']:checked:after{
    width: 13px;
    height: 13px;
    border-radius: 13px;
    top: 0px;
    position: relative;
    background-color: $color-pv-bg-dark-purple;
    content: '';
    display: block;
    visibility: visible;
    border: 1px solid $color-pv-bg-dark-purple;
    }

input[type=checkbox] {
    margin: 4px 0 0;
    margin-top: 2px;
    line-height: normal;
    width:15px;
    height:15px;
}

.checkbox label, .radio label
{
     font-family: $font-family-avenir;
}

.pv-forms > input{
    font-family: $font-family-avenir-light !important;   //TODO: Använd Avenir Next Light
}

.pv-forms > label{
    font-family: $font-family-avenir-demi !important;  //TODO: Använd Avenir Next Demi
}