﻿.pv-layout {

    &-wide {
        padding: 0;
    }

    &-left {
        margin-bottom: $footer-margin;
    }
}
