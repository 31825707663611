﻿
#mobile-menu {

    .navbar-header {
        background-color: $color-pv-bg-light-brown;
        padding-top: 8px;
    }

    .navbar-paddings {
        padding: 15px 15px 15px 15px;
    }

    .pv-login {
        color: $color-pv-txt-darker-purple;


        & a {
            font-size: 16px;
            text-align: right;
        }

        & a:hover {
            text-decoration: none;
        }
    }

    @media screen and (max-width: 300px) {
        .mobilmeny-text {
            display: none;
        }
    }

    .pv-menu-search {
        margin-right: 20px;
    }

    .paddingleft-zero {
        padding-left: 0;
    }

    .glyphicon-lock {
        padding-right: 8px;
    }

    .navbar-floatleft {
        float: left;
    }

    .navbar-floatright {
        float: right;
    }

    .navbar-toggle, .navbar-collapse, .navbar-toggle:focus, .navbar-toggle:hover {
        border: none;
        background-color: transparent;
    }

    .navbar-toggle-nomargin {
        margin: 0;
        padding: 0;
    }

    .navbar-toggle {

        .icon-bar {
            background-color: $color-pv-bg-dark-purple;
            height: 4px;
            margin-bottom: 6px;
            width: 35px;
        }

        .top-bar {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
        }

        .middle-bar {
            opacity: 0;
        }

        .bottom-bar {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
        }
    }

    .navbar-toggle.collapsed {
        .top-bar {
            transform: rotate(0);
        }

        .middle-bar {
            opacity: 1;
        }

        .bottom-bar {
            transform: rotate(0);
        }
    }

    .navbar-brand {
        font-family: $font-family-sans-serif;
        font-size: $font-size-small;
        color: $color-pv-bg-dark-purple;
        font-weight: 600;
        margin: 0 0 -10px 15px;
        height: 0;
    }

    .navbar-collapse {
        background-color: #fff;
    }

    a {
        font-size: 16px;
        border-radius: 0;
        text-align: left;
    }


    a:hover {
        text-decoration: none;
    }


    .is-active {
        font-weight: bold;
    }



    .kpa-nav--mobile {
        margin: 0;
        background-color: #eee7e3;

        &-menu {
            .menu-topborder {
                border-top: solid 1px $color-pv-bg-dark-purple;
                margin-top: 5px;
            }
        }


        &-icon {
            text-align: center;

            & svg path {
                fill: $color-pv-txt-darker-purple;
            }
        }

        &-landingpage {
            position: relative;
            font-weight: 600;
            margin-bottom: 10px;

            a {
                font-size: 14px;
                position: relative;
                top: -1px;
            }

            a:hover {
                color: #fff;
            }

            &.btn {
                padding: 3px 4px;
            }

            &-active {
                background-color: $color-pv-logo;
                color: #fff;

                a {
                    color: #fff;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 10px solid $color-pv-logo;
                }
            }
        }
    }

    #kpa-nav-top-mobilmeny {

        ul {
            padding-left: 0;
        }

        font-size: 16px;
        text-align: left;

        a.menu-level2 {
            padding-left: 20px;
        }

        a.menu-level3 {
            padding-left: 40px;
        }

        a.menu-level4 {
            padding-left: 60px;
        }

        a.menu-level5 {
            padding-left: 80px;
        }

        a.menu-level6 {
            padding-left: 100px;
        }
    }
}
