﻿
/*Huvudfärger*/
$color-pv-logo: #795b76; /*logotypfärg*/
$color-pv-logo-lighter: #a8679a;

/*Färgpalett för PVHemsida*/

/*Färger rekommenderade för bakgrunder*/
$color-pv-bg-dark-purple: #8f6b87;
$color-pv-bg-medium-purple: #d4b2d0;
$color-pv-bg-light-purple: #f5e2f3;

$color-pv-bg-dark-green: #5d817d;
$color-pv-bg-medium-green: #a9cdbe;
$color-pv-bg-light-green: #d9efe2;

$color-pv-bg-dark-blue: #5a7a9e;
$color-pv-bg-medium-blue: #94b7dc;
$color-pv-bg-light-blue: #d8e4f3;

$color-pv-bg-dark-brown: #7f736c;
$color-pv-bg-medium-brown: #b3a7a0;
$color-pv-bg-light-brown: #eee7e3;

$color-pv-bg-dark-grey: #b4b4b4;
$color-pv-bg-medium-grey: #efefef;

/*Färger rekommenderade för text*/
$color-pv-txt-darker-purple: $color-pv-logo;
$color-pv-txt-lighter-purple: $color-pv-logo-lighter;

$color-pv-txt-darker-green: #486d69;
$color-pv-txt-lighter-green: #3f8a82;

$color-pv-txt-darker-blue: #4b6685;
$color-pv-txt-lighter-blue: #3c6faa;

$color-pv-txt-darker-brown: #685d54;
$color-pv-txt-lighter-brown: #8c725d;

$color-pv-txt-darker-grey: #444444;
$color-pv-txt-lighter-grey: #8c8c8c;