﻿.pv-table {
    & thead tr {
        background-color: $color-pv-bg-dark-purple;
        color: white;
    }

    & th {
        font-weight: 400;
        font-size: 14px;
    }

    & tbody > tr > td, thead > tr > th {
        border: 1px solid white;
    }


    & thead > tr > th {
        text-align: center;
    }

    & tbody > tr > td {
        text-align: right;
        padding-right: 20px;
    }

    & tbody > tr:nth-of-type(odd) {
        background-color: $color-pv-bg-light-brown;
    }

    & tbody > tr:nth-of-type(even) {
        background-color: $color-pv-bg-medium-grey;
    }
}