﻿.pv-startpage {

    &-rubrik {
        text-align: center;
        margin-bottom: 64px;
        margin-top: 39px;
    }

    &-underrubrik {
        padding-top: 30px;
        padding-bottom: 25px;
    }

    &-aktuellyta {
        background-color: $color-pv-bg-light-purple;

        &-inner {
            text-align: center;
            margin-top: 50px;
            padding-bottom: 30px;
        }
    }

    &-aktuellt {
        margin: 0;

        .col-md-6 {
            padding: 15px;
        }

        &-rubriklank {
            padding-top: 30px;
        }
    }

    &-tjanstyta {
        padding: 0;
    }

    &-tjanster {
        .col-md-4 {
            padding: 5px 15px;
        }
    }

    & .pv-footer {
        margin-top: 0px;
    }
}
