﻿/*Här lägger vi in namn och värden på Bootstrap sassvaribler vi vill ersätta*/
/*Se http://getbootstrap.com/customize/ för namn på variabler*/

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$brand-primary:         $color-pv-logo;

//** Link hover color set via `darken()` function.
$link-hover-color:      $color-pv-logo-lighter;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Open Sans', sans-serif;
$font-family-avenir:       'AvenirNextLTW01-Regular', avenir;
$font-family-avenir-medium:  'AvenirNextLTW01-Medium', avenir;
$font-family-avenir-bold:  'AvenirNextLTW01-Bold', avenir;
$font-family-avenir-light: 'AvenirNextLTW01-Light', avenir;
$font-family-avenir-demi: 'AvenirNextLTW01-Demi', avenir;
$font-family-berling:      'BerlingNovaTextW04-Regular', berling;
$font-family-berling-bold:     'BerlingNovaTextW04-Bold', berling;

$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          18px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~23px
$font-size-xlarge:        ceil(($font-size-base * 2)); // ~36px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~15px

$font-size-h1:            floor(($font-size-base * 2.35)); // ~42px
$font-size-h2:            floor(($font-size-base * 1.8)); // ~32px
$font-size-h3:            ceil(($font-size-base * 1.25)); // ~22px
$font-size-h4:            $font-size-base; // ~18px
$font-size-h5:            ceil(($font-size-base * 0.9)); //~16
$font-size-h6:            ceil(($font-size-base * 0.7)); // ~12


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
$btn-default-color:              #fff;
$btn-default-bg:                 $color-pv-logo;
$btn-default-border:             darken($color-pv-logo, 5%);

//== Navbar
//
//##

// Basics of a navbar
$navbar-default-bg:                #fff;

// Navbar links
$navbar-default-link-color:                $color-pv-logo;
$navbar-default-link-hover-color:          $color-pv-txt-darker-purple;