﻿.btn-pv {
    width: auto;
    padding: 10px 40px;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.btn-white {
    background: #fff;
    color: $color-pv-txt-darker-purple;
    border-color: $color-pv-logo;

    &:hover {
        background-color: $color-pv-logo;
        border-color: transparent;
    }
    &:focus {
        background-color: $color-pv-logo;
        border-color: transparent;
    }
}

.btn-default {
    background: $color-pv-bg-dark-purple;
    border-color: transparent;

    &:hover {
        background-color: $color-pv-logo-lighter;
        border-color: transparent;
    }
    &:focus {
        background-color: $color-pv-logo;
        border-color: transparent;
    }
}

.btn-right {
    float: right;
}

.btn:disabled, input[type=submit]:disabled {
    background-color: $color-pv-bg-light-purple !important;
}

.btn:focus {
    outline-color: none !important;
}

.btn:active {
    -webkit-box-shadow: none;
    webkit-box-shadow: none;
}

.btn-abort {
    background-color: $color-pv-bg-medium-purple !important;
}

.btn-abort:focus {
    border: none !important;
}

.btn-inactive {
    background-color: $color-pv-bg-light-purple !important;
}

.pv-form-btn-well {
    float: left;
    width: 100%;
}
