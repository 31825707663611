﻿.pv-nav-top {
    background: $color-pv-bg-light-brown;
    /*Postionerar menyn i mitten av sidan*/
    .navbar-nav {
        float: none;
        margin: 0 auto;
        display: block;
        text-align: center;

        & li {
            border-right: solid 1px #fff;
            /*De nedan gör att margin på li tas bort*/
            display: inline-block;
            float: none;
            margin: 0 -0.125em;
        }

        & li:first-child {
            border-left: solid 1px #fff;
        }

        & li:hover {
            background: $color-pv-bg-light-purple;
        }

        & li:active {
            background: $color-pv-bg-dark-purple;
        }

        & li > a {
            font-family: $font-family-sans-serif;
            font-weight: 600;
           
        }

        & li > a:active {
            color: white;
        }

        & .menu-ancestor {
            background: $color-pv-bg-dark-purple;
            color: white;
        }
    }
}

.nav > li > a{
    padding-left: 30px;
    padding-right: 30px;
}

