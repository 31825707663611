﻿
$footer-height-desktop: 228px;
$footer-height-mobile: 535px;

.pv-footer {
    background: $color-pv-bg-dark-purple;
    color: white;
    height: $footer-height-desktop;
    position: absolute;
    bottom: 0;
    width: 100%;

    h1, h2, h3, h4, h5, a {
        color: white;
    }
}

.pv-footer-inner {
    padding: 54px 0;
}

body {
    padding-bottom: $footer-height-desktop + 30px;
}

/* Small devices (tablets, 768px and up) */
@media (max-width: $screen-sm-max) { 
    .pv-footer-item {
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .pv-footer {
        margin-top: 30px;
        height: $footer-height-mobile;
    }

    body {
        padding-bottom: $footer-height-mobile + 30px;
    }
}

@media (min-width: 1100px) and (max-width: 1300px) {
    .pv-footer-container
    {
        padding-left: 50px;
    }
}

@media (min-width: 1300px) {
    .pv-footer-container
    {
        padding-left: 100px;
    }
}
