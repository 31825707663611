﻿#left-menu, .kpa-nav--mobile-menu {
    /* Disable transition for the menu*/
    .collapsing {
        -webkit-transition: none;
        transition: none;
        display: none;
    }

    ul {
        padding-left: 0;
    }

    li {
        list-style: none;
        width: 100%;
        overflow-wrap: break-word;
        clear: both;

        a {
            padding: 14px 1em;
            display: block;
            text-decoration: none;
            background-color: #fff;

            &:hover {
                background-color: $color-pv-bg-light-purple;
                display: block;
            }

            &.menu-current:hover {
                background-color: $color-pv-bg-light-purple;
            }
        }
    }

    a.menu-current {
        font-weight: bold;
        display: block;
    }

    a.menu-children-expanded {
        font-weight: bold;
    }

    .menuitem-wrapper {
        float: left;
        width: 100%;

        .menu-link {
            float: left;
            width: calc(100% - 40px);
        }

        .menu-toggle {
            float: left;
            cursor: pointer;
            overflow: hidden;
        }

        .menu-toggle-inner {
            width: 40px;
            border-right: none;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            color: #795b76;
            display: inline-block;
            padding: 17.5px 9px;
            top: 0;

            &:hover {
                background-color: $color-pv-bg-light-purple;
            }
        }
    }
    /*Level 1*/
    .menu-level1 {
        .menuitem-wrapper {
            background-color: #fff;
        }
    }

    a.menu-level1 {
        background-color: #fff;
        font-size: 24px;
        font-weight: 600;
        padding-left: 0;

        &:hover {
            background-color: #fff;
            color: $color-pv-logo;
        }

        &.menu-current:hover {
            background-color: #fff;
        }
    }
    /*Level 2*/
    a.menu-level2 {
        padding-left: 30px;
        background-color: $color-pv-bg-light-brown;
    }

    .menu-level2 > div.menuitem-wrapper {
        border-bottom: solid 1px $color-pv-bg-dark-purple;
        background-color: $color-pv-bg-light-brown;

        .menu-link {
            border-right: solid 1px $color-pv-bg-dark-purple;
        }
    }
    /*Level 3*/
    a.menu-level3 {
        padding-left: 50px;
        background-color: $color-pv-bg-light-brown;
    }

    .menu-level3 > div.menuitem-wrapper {
        background-color: $color-pv-bg-light-brown;
        border-bottom: solid 1px $color-pv-bg-dark-purple;

        .menu-link {
            border-right: solid 1px $color-pv-bg-dark-purple;
        }
    }

    .menu-level2, .menu-level3 {
        & a.menu-current, &.menu-current > div.menuitem-wrapper {
            background-color: #ded6d1;
        }
    }
    /*Level 4*/
    a.menu-level4 {
        padding-left: 70px;
        background-color: #f8f8f8;
        font-size: 16px;
    }

    .menu-level4 > div.menuitem-wrapper {
        background-color: #f8f8f8;
        border-bottom: solid 1px $color-pv-bg-medium-grey;

        .menu-link {
            border-right: solid 1px $color-pv-bg-medium-grey;
        }

        .menu-toggle-inner {
            padding: 16px 9px;
        }
    }
    /*Level 5*/
    a.menu-level5 {
        padding-left: 90px;
        background-color: #f8f8f8;
        font-size: 16px;
    }

    .menu-level5 > div.menuitem-wrapper {
        background-color: #f8f8f8;
        border-bottom: solid 1px $color-pv-bg-medium-grey;

        .menu-link {
            border-right: solid 1px $color-pv-bg-medium-grey;
        }
         .menu-toggle-inner {
            padding: 16px 9px;
        }
    }

    .menu-level4, .menu-level5 {
        & a.menu-current, &.menu-current > div.menuitem-wrapper {
            background-color: #eaeaea;
        }
    }
    /*Level 6*/
    a.menu-level6 {
        padding-left: 110px;
        font-size: 16px;
    }

    .menu-level6 .menuitem-wrapper {
        border-bottom: solid 1px $color-pv-bg-medium-grey;
    }
}
