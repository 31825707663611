﻿.pv-block-inloggningblock {
    &--puff {
        padding: 0;

        &-rubrik {
            text-align: center;
            font-weight: 600;
        }

        &-inner {
            text-align: center;
            border-radius: 6px;
            padding: 30px 40px;
            margin-top: 20px;
            margin-bottom: 10px;

            & a {
                margin-top: 40px;
            }
        }

        &-merinfo {
            color: $color-pv-txt-lighter-brown;
        }
    }
}

.pv-sida-inloggningpage-inloggningar {

}
