﻿body {
    color: $color-pv-txt-darker-grey;
    min-height: 100%;
    position: relative;
}

html {
    overflow-y: scroll;
    height: 100%;
}

h1 {
    font-family: $font-family-berling;
    color: $color-pv-logo;
    margin-top: 0;
}

h2 {
    font-family: $font-family-berling;
    color: $color-pv-txt-darker-grey;
    margin-top: 0;
}

h3 {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    color: $color-pv-txt-darker-grey;
    margin-top: 0;
}

h4 {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    color: $color-pv-txt-darker-grey;
    margin-top: 0;
}


@media (max-width: $screen-xs-max) {
    .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
        padding: 0 20px;
    }
}

@media (max-width: $screen-sm-min) {
    $font-size-base: 16px;

    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 16px;
    }
}

@media (max-width: $screen-xs-min) {
    $font-size-base: 16px;

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
        line-height: 130%;
        font-weight: 800;
    }

    h4 {
        font-size: 16px;
    }
}
