﻿@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

@font-face {
	font-family: 'FontAwesome';
	src:url('fonts/fontawesome-webfont.eot?bj68bg');
	src:url('fonts/fontawesome-webfont.eot?bj68bg#iefix') format('embedded-opentype'),
		url('fonts/fontawesome-webfont.ttf?bj68bg') format('truetype'),
		url('fonts/fontawesome-webfont.woff?bj68bg') format('woff'),
		url('fonts/fontawesome-webfont.woff2?bj68bg#kpaikoner') format('woff'),
        url('fonts/fontawesome-webfont.svg?bj68bg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('fonts/glyphicons-halflings-regular.eot');
  src: url('fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), 
       url('fonts/glyphicons-halflings-regular.woff') format('woff'), 
       url('fonts/glyphicons-halflings-regular.ttf') format('truetype'), 
       url('fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Open Sans Regular'),local('OpenSans-Regular'),url(https://fonts.gstatic.com/s/opensans/v15/cJZKeOuBrn4kERxqtaUH3aCWcynf_cDxXwCLxiixG1c.ttf) format('truetype')
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: local('Open Sans SemiBold'),local('OpenSans-SemiBold'),url(https://fonts.gstatic.com/s/opensans/v15/MTP_ySUJH_bn48VBG8sNSonF5uFdDttMLvmWuJdhhgs.ttf) format('truetype')
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: local('Open Sans Bold'),local('OpenSans-Bold'),url(https://fonts.gstatic.com/s/opensans/v15/k3k702ZOKiLJc3WVjuplzInF5uFdDttMLvmWuJdhhgs.ttf) format('truetype')
}

/*AvenirNext*/
@font-face{
font-family:"AvenirNextLTW01-LightItalic";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightItalic.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightItalic.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightItalic.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightItalic.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightItalic.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightItalic.svg#216291a0-f927-4f75-9de7-6e36c7fd7ebf") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-MediumIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumIt.svg#ed4d3c45-af64-4992-974b-c37cd12a9570") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Bold";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Bold.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Bold.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Bold.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Bold.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Bold.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Bold.svg#ed104d8c-7f39-4e8b-90a9-4076be06b857") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Heavy";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Heavy.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Heavy.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Heavy.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Heavy.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Heavy.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Heavy.svg#3c111f4f-c9f7-45d4-b35f-4f4ed018842f") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-LightCond";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCond.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCond.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCond.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCond.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCond.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCond.svg#9ebde751-0201-4b52-a97b-03735fe6b845") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Thin";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Thin.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Thin.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Thin.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Thin.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Thin.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Thin.svg#91464696-717b-4eaa-adbd-0dfc5e5fd782") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Light";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Light.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Light.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Light.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Light.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Light.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Light.svg#869dc33b-3685-4d74-a51c-82b435579093") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-ThinCond";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCond.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCond.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCond.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCond.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCond.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCond.svg#b126092f-08f5-4f0a-8f08-3e9fdbb5b21e") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-LightCondIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCondIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCondIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCondIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCondIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCondIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-LightCondIt.svg#4ac2b0ee-8cde-4460-a55a-bc8b93e9486f") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-BoldItalic";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldItalic.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldItalic.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldItalic.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldItalic.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldItalic.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldItalic.svg#ab1835cb-df6f-4d8d-b8ee-3075f5ba758d") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-ThinItalic";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinItalic.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinItalic.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinItalic.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinItalic.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinItalic.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinItalic.svg#c110f1ef-6fdf-4008-bc75-b370649384a3") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-HeavyItalic";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyItalic.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyItalic.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyItalic.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyItalic.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyItalic.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyItalic.svg#9b40a6ef-0ef5-49c0-aa8d-5ba7e8e7d9b7") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-ThinCondIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCondIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCondIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCondIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCondIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCondIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-ThinCondIt.svg#798dbe11-6b08-49bf-b9fb-686ae6189b51") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-UltLightCn";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightCn.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightCn.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightCn.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightCn.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightCn.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightCn.svg#a30a07b5-d5bc-4f00-a949-20f62723d116") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-DemiCond";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCond.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCond.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCond.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCond.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCond.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCond.svg#f9659813-f8c7-4548-9117-b8e02fa26641") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Condensed";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Condensed.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Condensed.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Condensed.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Condensed.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Condensed.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Condensed.svg#94f6e6be-004d-4b3a-8b0e-f07609dc1726") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-UltLtCnIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLtCnIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLtCnIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLtCnIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLtCnIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLtCnIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLtCnIt.svg#025a5227-5321-4f52-9c20-85176659a709") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-CondensedIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-CondensedIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-CondensedIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-CondensedIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-CondensedIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-CondensedIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-CondensedIt.svg#962508db-d57e-4eb1-a483-28a105ee2b25") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-BoldCondIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCondIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCondIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCondIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCondIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCondIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCondIt.svg#704e9f93-4543-421e-861d-7246e89734f7") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-MediumCnIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCnIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCnIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCnIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCnIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCnIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCnIt.svg#d5cfa01e-526b-462e-8873-2bb24ebad1d3") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-MediumCond";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCond.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCond.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCond.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCond.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCond.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-MediumCond.svg#9c91e8bb-b452-4e4f-b00c-dad5dadb10c6") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-BoldCond";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCond.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCond.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCond.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCond.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCond.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-BoldCond.svg#390c08bc-8d21-4af3-95a1-a73c21f189cd") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-DemiCondIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCondIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCondIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCondIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCondIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCondIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiCondIt.svg#a4ba042b-90d1-4acd-baea-706dee7fa81b") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-UltraLight";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltraLight.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltraLight.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltraLight.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltraLight.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltraLight.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltraLight.svg#9fd4ea0c-b19a-4b21-9fdf-37045707dd78") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-DemiItalic";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiItalic.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiItalic.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiItalic.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiItalic.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiItalic.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-DemiItalic.svg#4862b373-2643-46b1-b0b5-88537c52d15c") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Italic";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Italic.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Italic.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Italic.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Italic.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Italic.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Italic.svg#1de7e6f4-9d4d-47e7-ab23-7d5cf10ab585") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-HeavyCondIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCondIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCondIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCondIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCondIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCondIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCondIt.svg#9b9e7f1d-5c52-42b0-bb41-dabe370775ef") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-UltLightIt";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightIt.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightIt.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightIt.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightIt.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightIt.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-UltLightIt.svg#3966f856-9dcf-48e7-88e7-7400f1b7d619") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Medium";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Medium.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Medium.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Medium.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Medium.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Medium.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Medium.svg#a89d6ad1-a04f-4a8f-b140-e55478dbea80") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Demi";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Demi.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Demi.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Demi.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Demi.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Demi.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Demi.svg#99affa9a-a5e9-4559-bd07-20cf0071852d") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-HeavyCond";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCond.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCond.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCond.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCond.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCond.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-HeavyCond.svg#f5e460ec-29d2-437c-ace7-3dd23e7ae460") format("svg");
}
@font-face{
font-family:"AvenirNextLTW01-Regular";
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Regular.eot?#iefix");
src:url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Regular.eot?#iefix") format("eot"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Regular.woff2") format("woff2"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Regular.woff") format("woff"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Regular.ttf") format("truetype"),url("fonts/webfonts/AvenirNext/AvenirNextLTW01-Regular.svg#52a192b1-bea5-4b48-879f-107f009b666f") format("svg");
}



/*BerlingNova*/
@font-face{
font-family:"BerlingNovaTextW04-BoldIt";
src:url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-BoldIt.eot?#iefix");
src:url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-BoldIt.eot?#iefix") format("eot"),
    url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-BoldIt.woff2") format("woff2"),
    url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-BoldIt.woff") format("woff"),
    url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-BoldIt.ttf") format("truetype"),
    url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-BoldIt.svg#22a3c242-6786-47e9-8847-0351c467196d") format("svg");
}
@font-face{
font-family:"BerlingNovaTextW04-Bold";
src:url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Bold.eot?#iefix");
src:url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Bold.eot?#iefix") format("eot"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Bold.woff2") format("woff2"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Bold.woff") format("woff"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Bold.ttf") format("truetype"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Bold.svg#7c98b87e-5554-4bda-9c8a-f239af3a8ca4") format("svg");
}
@font-face{
font-family:"BerlingNovaDisplayW04-It";
src:url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-It.eot?#iefix");
src:url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-It.eot?#iefix") format("eot"),url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-It.woff2") format("woff2"),url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-It.woff") format("woff"),url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-It.ttf") format("truetype"),url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-It.svg#8fc6145f-59d5-4cd9-ba93-5454e2f304d1") format("svg");
}
@font-face{
font-family:"BerlingNovaTextW04-Italic";
src:url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Italic.eot?#iefix");
src:url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Italic.eot?#iefix") format("eot"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Italic.woff2") format("woff2"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Italic.woff") format("woff"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Italic.ttf") format("truetype"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Italic.svg#cd0c7c75-adbc-428a-b4a0-3914ffb87d38") format("svg");
}
@font-face{
font-family:"BerlingNovaDisplayW04-Rg";
src:url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-Rg.eot?#iefix");
src:url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-Rg.eot?#iefix") format("eot"),url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-Rg.woff2") format("woff2"),url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-Rg.woff") format("woff"),url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-Rg.ttf") format("truetype"),url("fonts/webfonts/BerlingNova/BerlingNovaDisplayW04-Rg.svg#62e7fbd8-103d-4278-9884-12abf57cb9b2") format("svg");
}
@font-face{
font-family:"BerlingNovaTextW04-Regular";
src:url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Regular.eot?#iefix");
src:url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Regular.eot?#iefix") format("eot"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Regular.woff2") format("woff2"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Regular.woff") format("woff"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Regular.ttf") format("truetype"),url("fonts/webfonts/BerlingNova/BerlingNovaTextW04-Regular.svg#bab27808-caa2-428a-a1a2-363721a64d7e") format("svg");
}




.BerlingNovaTextPro-Bold { 
	font-family: BerlingNovaTextPro-Bold;
	font-weight: normal;
	font-style: normal;
}
.AvenirLTStd-Light { 
	font-family: AvenirNextLTW01-BoldItalic;
	
}

.fontsizesmall{
    font-size: small;
}