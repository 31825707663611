﻿.pv-arrow-link,
.pv-arrow-link-nopadding { // Detta är lite rörigt eftersom "nopadding" används i WYSIWYG-editorn och sätts på en p-tagg som är förälder
                                 // till a-taggen medan "inline-lasmer" sätts direkt på a-taggen...
    display: inline-block;
    text-decoration: none;
    position: relative;
    font-weight: bold;

    // Detta tvingas vi till på grund av en bugg i IE
    // http://stackoverflow.com/questions/8536015/stop-links-before-content-from-being-underlined-by-rule-applied-to-the-link/31330454#31330454
    &:hover {
        text-decoration: underline;
    }

    &:after {
        text-decoration: underline;
    }

    &:after,
    &:hover:after {
        text-decoration: none;
    }

    &:after {
        @extend .glyphicon;
        content: "\e080";
        position: absolute;
        top: 0.2em;
        right: -1em;
    }
}

.pv-arrow-link {
    padding-left: .5em;
}