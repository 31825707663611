﻿/*Färgplattor*/
.pv-well-area{
    line-height:$line-height-text;
    & h5{
         line-height:$line-height-text;
    }
}
.pv-well-light-purple {
    background-color: $color-pv-bg-light-purple;
    color: $color-pv-txt-darker-purple;

    & a {
        color: $color-pv-txt-darker-purple;
    }
}

.pv-well-light-green {
    background-color: $color-pv-bg-light-green;
    color: $color-pv-txt-darker-green;

    & a {
        color: $color-pv-txt-darker-green;
    }
}

.pv-well-light-blue {
    background-color: $color-pv-bg-light-blue;
    color: $color-pv-txt-darker-blue;

    & a {
        color: $color-pv-txt-darker-blue;
    }
}

.pv-well-light-brown {
    background-color: $color-pv-bg-light-brown;
    color: $color-pv-txt-darker-brown;

    & a {
        color: $color-pv-txt-darker-brown;
    }
}

.well {
    margin: 36px 0;
    padding: 0;
}

.pv-well-inner {
    padding-top: 30px;
    padding-bottom: 20px;
    margin: 0 26px;
}

@media (max-width: $screen-sm-max) {
    .well {
        margin: 32px 0;
    }
}
