﻿/*Brödtext*/
.pv-brodtext {
    margin-top: 15px;
}

/*Ingress*/
.pv-ingress {
    line-height: 1.9em;
}

/*Färgplattor*/
.pv-editor-well-light-purple{
    background-color: $color-pv-bg-light-purple;
    color: $color-pv-txt-darker-purple;

    & a {
        color: $color-pv-txt-darker-purple;
    }
}
.pv-editor-well-light-green{
    background-color: $color-pv-bg-light-green;
    color: $color-pv-txt-darker-green;

    & a {
        color: $color-pv-txt-darker-green;
    }
}
.pv-editor-well-light-blue{
    background-color: $color-pv-bg-light-blue;
    color: $color-pv-txt-darker-blue;

    & a {
        color: $color-pv-txt-darker-blue;
    }
}

.pv-editor-well-light-brown{
    background-color: $color-pv-bg-light-brown;
    color: $color-pv-txt-darker-brown;

    & a {
        color: $color-pv-txt-darker-brown;
    }
}

/*Accordion*/
.pv-accordion-header
{
    background-color: white;
    border-top: solid 1px $color-pv-bg-dark-purple;
    border-bottom: solid 1px $color-pv-bg-dark-purple;
}
.pv-editor-accordion-body
{
        background-color: $color-pv-bg-light-brown;
}
