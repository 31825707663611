﻿.pv-nav {
    padding: 20px 0;

    .navbar-right {
        font-family: $font-family-sans-serif;

        & li.pv-search {
            border-right: solid 1px $color-pv-bg-dark-purple;
            margin-top: 20px;
            color: $color-pv-txt-darker-purple;
        }

        & li.pv-login {
            color: $color-pv-txt-darker-purple;
            font-size: 16px;
        }

        & li {
            margin-top: 20px;
        }

        & li > a {
            display: inline;
            color: $color-pv-txt-darker-purple;
        }

        & li:last-child {
            margin-left: 15px;
        }
    }
}

.navbar-brand {
       width: 203px;
}

.navbar-brand > picture > img {
    height: 100%;
    width: auto;

    @media screen and (max-width: 767px) {
        margin-left: 20px;
    }
}


