﻿.breadcrumbText {
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 40px;
}

.pv-page {
    margin-bottom: $footer-margin;
}


.pv-page-toppbild img {
    width: 100%;
    padding-bottom: 20px;
}
