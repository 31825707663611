﻿.pv-sida-inloggningpage {

    &-rubrik {
        text-align: center;
        margin-bottom: 50px;
    }

    &-inloggningyta {
        padding: 0;
    }

    &-inloggningar {
        .col-md-6 {
            padding: 15px;
        }
    }

}
