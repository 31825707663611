﻿.pv-block-startsidaaktuelltpuff {

    &--puff {
        padding: 0;

        &-inner {
            background: white;
            text-align: center;
            border-radius: 6px;
            padding: 50px 60px;

            &-rubrik {
                font-size: $font-size-large;
                margin-top: 30px;
                margin-bottom: 10px;
            }

            &-ingress {
                color: $color-pv-txt-darker-grey;
            }

            & img {
                max-width: 100%;
            }
        }
    }
}
