﻿.pv-driftmeddelande {
    $mobileBreakpoint: 400px;
    background-color: #f9e6ea;
    padding-top: 15px;
    margin-top: -24px;

    &--ikon {
        font-size: 36px;
        float: left;
        color: #ad495c;

        @media (max-width: $mobileBreakpoint) {
            font-size: 24px;
        }
    }

    &--innehall {
        margin-left: 46px;

        @media (max-width: $mobileBreakpoint) {
            margin-left: 34px;
        }
    }

    &--rubrik {
        font-size: 20px;
        margin-bottom: 5px;
    }
}
